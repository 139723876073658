import React from "react"
import Layout from "../components/layout"
import Banner from "../components/banner"
import ProjectList from "../components/project-list"
import { graphql } from "gatsby"

export default function Home({ data }) {

  const recentWork = data.allMarkdownRemark.edges.filter(({ node }) => (node.frontmatter.recent == true));

  const bannerUrl = "sam-lion-banner.jpg";

  return (
    <Layout>
      <Banner title="Creating online experiences for bighearted people." button="Get in touch." imageUrl={bannerUrl}>
      </Banner>

      <section className="section">
        <div className="container">
          <ProjectList projects={recentWork} layout="half" title="Recent Work" />
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="content below-the-fold-section">
            <h2 className="title is-2">Below the Fold</h2>
            <div className="link-with-icon">
              <a href="https://belowthefold.ca/" className="is-size-5 link-navy-to-peach transition">Checkout what my husband and I have been up to at our studio
                <div className="pointing-icon-container transition">
                  <img src="/pointing-right-icon-navy.svg" className="pointing-icon navy transition"></img>
                  <img src="/pointing-right-icon-peach.svg" className="pointing-icon peach transition"></img>
                </div>
              </a>
            </div>
            <img src="below-the-fold-banner-2.jpg"></img>
          </div>
        </div>
      </section>    
    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            title
            category
            thumbnail
            mediaRoot
            recent
          }
          excerpt
        }
      }
    }
  }
`